<template>
  <div class="product">
    <div class="zmhd">
      <div class="zmhd_box">
        <p>移动办公</p>
        <p>摆脱办公室束缚</p>
        <p>使用移动办公实现全天候办公，流程不耽搁，签批不等待</p>
        <el-button>在线咨询</el-button>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/ydbg/hxld.png" />
        <div class="hxld_box_item" id="title">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/ydbg/hxld_icon1.png"
            />
            <p class="first_line">移动签批</p>
            <p class="second_line">随时随地签批文件</p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/ydbg/hxld_icon2.png"
            />
            <p class="first_line">指尖查阅</p>
            <p class="second_line">
              工作文件、会议信息、<br />工作日程等指尖上随时查看
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="tx">
      <div class="tx_box">
        <div class="tx_box_item" id="title2">
          <div class="title">
            <img class="icon" src="@/assets/images/product/ydbg/tx_icon1.png" />
            <p class="first_line">即时消息</p>
            <p class="second_line">自动推动消息，<br />不漏掉每一条重要消息</p>
          </div>
          <div class="title">
            <img class="icon" src="@/assets/images/product/ydbg/tx_icon2.png" />
            <p class="first_line">即时通讯</p>
            <p class="second_line">
              通讯录内架构清晰，<br />快速搜索，即时联系
            </p>
          </div>
        </div>
        <img class="img" id="img2" src="@/assets/images/product/ydbg/tx.png" />
      </div>
    </div>
    <div class="xxtx">
      <img class="img" id="img3" src="@/assets/images/product/ydbg/xxtx.png" />
      <div class="xxtx_box_item" id="title3">
        <div class="title">
          <img class="icon" src="@/assets/images/product/ydbg/xxtx_icon1.png" />
          <p class="first_line">随需定制</p>
          <p class="second_line">随实际需要，定制、<br />新增移动办公功能</p>
        </div>
        <div class="title">
          <img class="icon" src="@/assets/images/product/ydbg/xxtx_icon2.png" />
          <p class="first_line">多样适配</p>
          <p class="second_line">
            手机、平板皆可适配使用<br />同时适用于安卓（ Android）、<br />苹果（ios）系统
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var hxldH = $('.hxld').offset().top
      var txH = $('.tx').offset().top
      var xxtxH = $('.xxtx').offset().top
      if (
        h > hxldH - 500
      ) {
        $('#img').addClass('animation')
        $('#title').addClass('animation')
      }
      if (h > txH - 500) {
        $('#img2').addClass('animation')
        $('#title2').addClass('animation')
      }
      if (
        h > xxtxH - 500
      ) {
        $('#img3').addClass('animation')
        $('#title3').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
